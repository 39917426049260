/* ====== CSS Import ====== */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
@import "react-calendar/dist/Calendar.css";

:root {
  --color-foreground: #f6fcf9;
  --color-primary: #4eb73f;
  --color-secondary: #aee43e;
  --color-gray: #707070;
  --color-lightgray: #dbdbdb;
  --color-thingray: #f1f1f1;
  --color-cD9: #d9d9d9;
  --color-cD5: #d5d5d5;
  --color-mainBackground: transparent;
}
/* ====== CSS Import ====== */

/* ====== Common CSS ====== */

.flex-center {
  @apply flex items-center justify-center;
}

*::-webkit-scrollbar {
  @apply w-2 h-2;
}
*::-webkit-scrollbar-track {
  @apply w-2 h-2 bg-gray/20;
}
*::-webkit-scrollbar-thumb {
  @apply w-2 h-2 bg-gray/50 rounded-full transition-all;
}
*::-webkit-scrollbar-thumb:hover {
  @apply bg-gray;
}

/* ====== Common CSS ====== */

/* ====== Table CSS ====== */

.noHead table thead {
  @apply hidden;
}

/* ====== Table CSS ====== */

[data-tooltip] {
  position: relative;
}
[data-tooltip]::before,
[data-tooltip]::after {
  text-transform: none;
  font-size: 0.9em;
  line-height: 1;
  user-select: none;
  pointer-events: none;
  position: absolute;
  display: none;
  opacity: 0;
}
[data-tooltip]::before {
  content: "";
  border: 5px solid transparent;
  z-index: 1001;
}
[data-tooltip]::after {
  content: attr(data-tooltip);
  text-align: center;
  min-width: 3em;
  max-width: 21em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1ch 1.5ch;
  border-radius: 6px;
  box-shadow: 0 1em 2em -0.5em rgba(0, 0, 0, 0.35);
  background: #ffffff;
  color: var(--white);
  z-index: 1000;
}
[data-tooltip]:hover::before,
[data-tooltip]:hover::after {
  display: block;
}
[tooltip=""]::before,
[tooltip=""]::after {
  display: none !important;
}
[data-tooltip]:not([flow])::before,
[data-tooltip][flow^="up"]::before {
  bottom: calc(100% + 5px);
  border-bottom-width: 0;
  border-top-color: #ffffff;
}
[data-tooltip]:not([flow])::after,
[data-tooltip][flow^="up"]::after {
  bottom: calc(100% + 10px);
}
[data-tooltip]:not([flow])::before,
[data-tooltip]:not([flow])::after,
[data-tooltip][flow^="up"]::before,
[data-tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -0.5em);
}
[data-tooltip][flow^="down"]::before {
  top: calc(100% + 5px);
  border-top-width: 0;
  border-bottom-color: #ffffff;
}
[data-tooltip][flow^="down"]::after {
  top: calc(100% + 10px);
}
[data-tooltip][flow^="down"]::before,
[data-tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, 0.5em);
}
[data-tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #ffffff;
  left: calc(0em - 10px);
  transform: translate(-0.5em, -50%);
}
[data-tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 10px);
  transform: translate(-0.5em, -50%);
}
[data-tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #ffffff;
  right: calc(0em - 10px);
  transform: translate(0.5em, -50%);
}
[data-tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 10px);
  transform: translate(0.5em, -50%);
}
@keyframes tooltips-vert {
  to {
    opacity: 0.9;
    transform: translate(-50%, 0);
  }
}
@keyframes tooltips-horz {
  to {
    opacity: 0.9;
    transform: translate(0, -50%);
  }
}
[data-tooltip]:not([flow]):hover::before,
[data-tooltip]:not([flow]):hover::after,
[data-tooltip][flow^="up"]:hover::before,
[data-tooltip][flow^="up"]:hover::after,
[data-tooltip][flow^="down"]:hover::before,
[data-tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
}
[data-tooltip][flow^="left"]:hover::before,
[data-tooltip][flow^="left"]:hover::after,
[data-tooltip][flow^="right"]:hover::before,
[data-tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
}

.react-daterange-picker__wrapper {
  border-radius: 5px;
  height: 50px;
  padding: 0 10px;
}

.react-daterange-picker__wrapper input,
.react-daterange-picker__wrapper input:active,
.react-daterange-picker__wrapper input:hover {
  outline: none;
}
.react-daterange-picker__range-divider {
  margin-right: 15px;
}

#okta-sign-in {
  margin: 0 !important;
}

/* @media screen and (min-width: 768px) {
  .auth0-lock-overlay {
    visibility: hidden;
  }

  .auth0-lock-widget-container {
    visibility: visible;
  }

  .auth0-lock-widget {
    margin-left: 60vw !important;
  }
}

#undefined-close-button {
    visibility: hidden;
}

.auth0-lock-close-button {
  visibility: hidden;
} */

.auth0-lock-widget {
  /* width: 350px !important; */
  margin: 0px !important;
  width: 100% !important;
}
.auth0-lock-cred-pane-internal-wrapper {
  height: auto !important;
  margin-top: -70px;
}

@media screen and (min-width: 768px) {
  .auth0-lock-center {
    margin: 0px;
    display: unset !important;
    padding: 0px !important;
  }

  .auth0-lock-widget {
    margin: 0px !important;
    width: 350px !important;
  }
  .auth0-lock-cred-pane-internal-wrapper {
    margin-top: unset;
  }
}

.reset-tw,
.reset-tw * {
  all: revert !important;
}
