@font-face {
  font-family: 'Arial';
  src: url('../fonts/ArialMT.eot');
  src: url('../fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ArialMT.woff2') format('woff2'),
      url('../fonts/ArialMT.woff') format('woff'),
      url('../fonts/ArialMT.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Arial';
  src: url('../fonts/Arial-BoldMT.eot');
  src: url('../fonts/Arial-BoldMT.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Arial-BoldMT.woff2') format('woff2'),
      url('../fonts/Arial-BoldMT.woff') format('woff'),
      url('../fonts/Arial-BoldMT.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

